<template>
  <div class="main">
    <div class="box">
        <h1>{{new Date().getFullYear()}}</h1>
        <div class="calendar-container">
          <div class="calendar-item" v-for="(item, idx) in calendar "  :key="idx" >
            <p class="calendar-name">{{item.name}}</p>
            <ul>
              <li v-for="(event, idy) in item.events" :key="idy">■ {{event}}</li>
            </ul>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Calendar",
  data() {
    return {
      calendar: [
        {
          name : this.$t("calendar.january"),
          value : 1,
          events : []
        },
        {
          name : this.$t("calendar.february"),
          value : 2,
          events : []
        },
        {
          name : this.$t("calendar.march"),
          value : 3,
          events : []
        },
        {
          name : this.$t("calendar.april"),
          value : 4,
          events : [            
          ]
        },
        {
          name : this.$t("calendar.may"),
          value : 5,
          events : []
        },            
        {
          name : this.$t("calendar.june"),
          value : 6,
          events : []
        },
        {
          name : this.$t("calendar.july"),
          value : 7,
          events : []
        },
        {
          name : this.$t("calendar.august"),
          value : 8,
          events : []
        },
        {
          name : this.$t("calendar.september"),
          value : 9,
          events : []
        },
        {
          name : this.$t("calendar.october"),
          value : 10,
          events : []
        },
        {
          name : this.$t("calendar.november"),
          value : 11,
          events : []
        },            
        {
          name : this.$t("calendar.december"),
          value : 12,
          events : []
        },
      ],
    }
  },
  methods : {
      getData() {
        let config = {
            validateStatus: () => true,  
        };
        let vm = this;

        axios
            .get(`${this.$store.state.apiUrl}/public/events/2/${this.$i18n.locale}`,  config)
            .then(function (res) {                
                if(res.status != 200) {
                    console.log('Error => ' + res.data.errors)                  
                }else{
                    const events = res.data.message; 
                    vm.calendar.forEach(element => {
                      let eventMonth = [];
                      events.forEach(el => {
                        el.text && el.month == element.value ? eventMonth.push(el.text) : null;
                      }); 
                      element.events = eventMonth;
                    });               
                }
            });
      },
  },
  mounted() {
    this.getData();
  },
  watch  : {
    "$i18n.locale" : function() {
      this.getData();
      this.calendar[0].name = this.$t("calendar.january")
      this.calendar[1].name = this.$t("calendar.february")
      this.calendar[2].name = this.$t("calendar.march")
      this.calendar[3].name = this.$t("calendar.april")
      this.calendar[4].name = this.$t("calendar.may")
      this.calendar[5].name = this.$t("calendar.june")
      this.calendar[6].name = this.$t("calendar.july")
      this.calendar[7].name = this.$t("calendar.august")
      this.calendar[8].name = this.$t("calendar.september")
      this.calendar[9].name = this.$t("calendar.october")
      this.calendar[10].name = this.$t("calendar.november")
      this.calendar[11].name = this.$t("calendar.december")
    }
  },

  
}
</script>

<style scoped lang="scss">

h1 {
  color:$mainColor;
  font-size:40px;
  font-weight:400;
  text-align: center;
  margin-top:40px;
}

ul {
  padding:0;
  margin:0;
  list-style: none;
}

li {
  color:#fff;
  font-size:15px;
  font-weight: 300;
  margin-bottom: 20px;
  line-height:25px;
}

.calendar-name {
  color:$mainColor;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom:20px;
  font-size:18px;
}

.calendar-item {
  width:21%;
  margin:20px 2%;
}

.calendar-container {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width:1000px){
  .calendar-item {
    width:46%;
  }
}

@media screen and (max-width:768px){
  .calendar-item {
    width:96%;
  }
}
</style>